import axios from '@/axios-zaconte';
import CustomError from '@/classes/models/CustomError';

interface ImportFileDto {
  file: File;
}

export interface ImportStateDto {
  state: string;
  error?: string;
  percentDone: number;
}

export class ImportApi {
  static async ImportFile(data: ImportFileDto): Promise<string> {
    try {
      const dataBody = new FormData();
      dataBody.append('file', data.file);
      return (await axios.post('/import', dataBody)).data as string;
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  static async GetStatus(guid: string): Promise<ImportStateDto> {
    try {
      return (await axios.get(`/import/${guid}`)).data as ImportStateDto;
    } catch (error) {
      throw new CustomError(error.message);
    }
  }
}
