
import { defineComponent } from 'vue';

import FileUpload from 'primevue/fileupload';
import Card from 'primevue/card';
import Panel from 'primevue/panel';
import ProgressBar from 'primevue/progressbar';

import { ImportApi } from '@/classes/api/ImportApi';

const STATES = {
  ['Undefined']: 0,
  ['Waiting']: 1,
  ['ReadingFile']: 2,
  ['CreatingDictionaries']: 3,
  ['CreatingImages']: 4,
  ['CreatingParts']: 5,
  ['Done']: 6,
  ['Error']: 7,
};

export default defineComponent({
  components: {
    Card,
    FileUpload,
    Panel,
    ProgressBar,
  },
  data: () => ({
    progress: 0,
    currentState: 0,
    loading: false,
    error: false,
  }),
  methods: {
    async importSelectedHandler(event) {
      this.progress = 0;
      this.error = false;
      this.currentState = 0;
      this.loading = true;
      try {
        const guid = await ImportApi.ImportFile({ file: event.files[0] });
        await this.checkState(guid);
        if (guid) {
          const monitor = setInterval(async () => {
            await this.checkState(guid, monitor);
          }, 200);
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.error = true;
      }
    },

    async checkState(guid: string, interval?: number) {
      try {
        const state = await ImportApi.GetStatus(guid);
        this.progress = state.percentDone;
        this.currentState = STATES[state.state];
        if (state.state === 'Done' || state.state === 'Error') {
          if (interval) {
            clearInterval(interval);
          }
          this.loading = false;
          if (state.state === 'Error') {
            this.error = true;
          }
        }
      } catch (error) {
        console.log(error);
        if (interval) {
          clearInterval(interval);
        }
        this.loading = false;
        this.error = true;
      }
    },

    selectFileHandler(event) {
      if (event.files.length > 1) {
        event.files = [event.files[0]];
      }
    }
  },
});
